html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

// ************************************************variables************************************************
$mainColor: #00aa31;
$green: #bed6c5;
$whiteColor: #ffffff;
$blackColor: #000000;
$lightGrey: #acacac;
$redColor: #e14c4c;
$grey: #c4c4c4;
$deepGrey: #565656;
$lightGreybck: #f7f7f7;
$cl-1: #e07e6180;
$cl-2: #d9b57d4d;
$cl-3: #bfe69c80;
$cl-4: #8cdfe366;
$yellow: #f7b203;
$blue: #02a0fc;
$light_blue: #8cdfe3;
$lightYellow: #fffabc;
$lightGreen: #c6e6d7;
$lightRed: #fbadad;
// ************************************************font-familly************************************************
@font-face {
  font-family: avenirMedium;
  src: url(assets/fonts/alfont_com_AlFont_com_AvenirArabic-Medium.ttf);
}
@font-face {
  font-family: avenirRegular;
  src: url(assets/fonts/alfont_com_AlFont_com_AvenirArabic-Medium.ttf);
}
@font-face {
  font-family: avenirRglr;
  src: url(assets/fonts/AvenirRegular.ttf);
}
@font-face {
  font-family: avenirMdmm;
  src: url(assets/fonts/AvenirRegular.ttf);
}
@font-face {
  font-family: avenirHeavy;
  src: url(assets/fonts/alfont_com_AlFont_com_AvenirArabic-Medium.ttf);
}
@font-face {
  font-family: avenirBlack;
  src: url(assets/fonts/alfont_com_AlFont_com_AvenirArabic-Medium.ttf);
}
@font-face {
  font-family: avenirBlck;
  src: url(assets/fonts/AvenirBlack.ttf);
}
@font-face {
  font-family: avenirArabic;
  src: url(assets/fonts/alfont_com_AlFont_com_AvenirArabic-Medium.ttf);
}
.avenirRglr {
  font-family: avenirRglr;
}
.avenirBlck {
  font-family: avenirBlck;
}
.avenirMedium {
  font-family: avenirArabic;
}
.avenirRegular {
  font-family: avenirRegular;
}
.avenirBlack {
  font-family: avenirBlack;
}
.avenirHeavy {
  font-family: avenirHeavy;
}
.avenirArabic {
  font-family: avenirArabic;

}
// ************************************************font-size************************************************
.f72 {
  font-size: 72px;
}
.f56 {
  font-size: 56px;
}
.f48 {
  font-size: 48px;
}
.f40 {
  font-size: 40px;
}
.f36 {
  font-size: 36px;
}
.f35 {
  font-size: 35px;
}
.f30 {
  font-size: 30px;
}
.f25 {
  font-size: 25px;
}
.f24 {
  font-size: 24px;
}
.f20 {
  font-size: 20px;
}
.f18 {
  font-size: 18px;
}
.f16 {
  font-size: 16px;
}
.f14 {
  font-size: 14px;
}
.f13 {
  font-size: 13px;
}
.f12 {
  font-size: 12px;
}
// ************************************************font-weight************************************************
.fw85 {
  font-weight: 850;
}
.fw60 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
// ************************************************opacity************************************************
.op-5 {
  opacity: 0.5;
}
.op-3 {
  opacity: 0.3;
}
// ************************************************colum Count************************************************
.cc-3 {
  column-count: 3;
}
.cc-2 {
  column-count: 2;
}
.cc-4 {
  column-count: 4;
}
// ************************************************Colors************************************************
.black {
  color: $blackColor !important;
}
.yellow_back {
  background-color: $lightYellow !important;
}
.light_green_back {
  background-color: $lightGreen !important;
}
.main-color,
.main-color :hover {
  color: $mainColor !important;
}
.light_red_back {
  background-color: $lightRed !important;
}
.greyColor {
  color: #acacac;
}
.grey {
  color: $grey;
}
.yellow {
  color: $yellow;
}
.blue {
  color: $blue;
}
.green {
  color: $green;
}
.nav-link,
.nav-link:hover {
  @extend .greyColor;
}
.router-link-active {
  @extend .black;
}
.deepGrey {
  color: $deepGrey;
}
// ************************************************Background-colors************************************************

.white-back {
  background-color: $whiteColor;
}
.green-back {
  background-color: $mainColor;
}
.green_back {
  background-color: $green;
}
.form-control:focus,
.btn:focus {
  box-shadow: unset !important;
}
.grey-back {
  background-color: #e5e5e5;
}
.red-back {
  background-color: $redColor;
}
.grey-background {
  background-color: $grey;
}
.dp-grey-back {
  background-color: $lightGrey;
}
.red-color {
  color: $redColor;
}
.light-grey-back {
  background-color: $lightGreybck;
}
.light_blue_back {
  background-color: $light_blue;
}
.back-1 {
  background-color: $cl-1;
}
.back-2 {
  background-color: $cl-2;
}
.back-3 {
  background-color: $cl-3;
}
.back-4 {
  background-color: $cl-4;
}
// ************************************************padding and margins************************************************
.pt-6 {
  padding-top: 4rem;
}
.pt-7 {
  padding-top: 6rem;
}
.px-6 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.mb-10 {
  margin-bottom: 10px;
}
// ************************************************border-radius************************************************
.br30 {
  border-radius: 30px;
}
.br25 {
  border-radius: 25px;
}
.br20 {
  border-radius: 20px;
}
.br40 {
  border-radius: 40px;
}
.br10 {
  border-radius: 10px;
}
.br5 {
  border-radius: 5px;
}
// ************************************************tables************************************************
table {
  & th {
    font-family: avenirRegular;
    color: $blackColor !important;
    font-size: 18px;
    font-weight: unset !important;
  }
}
.table td,
.table th {
  vertical-align: middle !important;
}
table tbody tr {
  background-color: $whiteColor;
  line-height: 45px;
}
.img-table {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
// ************************************************Buttons************************************************
.p-menu.p-menu-overlay {
  left: 81% !important;
  border: unset !important;
  width: 14rem !important;
}
.exportBtn {
  border: 2px solid #fff;
  border-radius: 5px;
}
// ************************************************Modals************************************************
.p-dialog {
  border: unset !important;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.p-dialog-content {
  padding: 1.75rem !important;
  border-radius: 5px;
}
.p-radiobutton .p-radiobutton-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #00aa31;
  background: #00aa31;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #00aa31;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgb(0 170 49 / 20%) !important;
  border-color: #00aa31;
}
// ************************************************genral CSS************************************************

.ursopct {
  opacity: 0.66;
}
.p-inputswitch .p-inputswitch-slider {
  border-radius: 25px;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: $mainColor;
}
.p-inputswitch .p-inputswitch-slider,
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider,
.inactive {
  background-color: $grey;
}
.p-inputswitch .p-inputswitch-slider:before {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  left: 3px;
  margin-top: -12.5px;
}
.p-inputswitch {
  width: 60px;
  height: 34px;
  display: flex !important;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(30px);
}
.bnr {
  background: #c6e6d78f;
  border-radius: 40px;
}
.p-dropdown,
.p-multiselect {
  width: 100%;
  background: rgb(172 172 172 / 20%);
  border-radius: 25px;
  border-color: $whiteColor !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  border-color: $whiteColor !important;
  box-shadow: unset !important;
}
.p-dropdown .p-dropdown-panel {
  top: 48px !important;
}
p-dropdownitem .ng-star-inserted {
  font-size: 16px;
}
.p-dropdown-label,
.p-multiselect-label.p-placeholder {
  @extend .ng-star-inserted;
  color: $deepGrey !important;
  font-family: "avenirMedium";
}
.p-menu .p-menuitem-link .p-menuitem-text {
  font-family: avenirMedium !important;
  font-size: 18px !important;
  color: $blackColor !important;
}
label {
  margin-bottom: unset !important;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: rgb(0 170 49 / 10%);
  color: $mainColor;
  font-family: "avenirMedium";
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #00aa31;
  background: #00aa31;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  font-family: "avenirMedium";
  font-size: 16px;
  color: $mainColor;
}
::placeholder {
  color: $deepGrey;
}
.custom-form-control,
.custom-form-control:focus {
  border-radius: 0px 25px 25px 0px;
  border: 1px solid $lightGrey;
}
.btn-sel {
  background: #fff;
  border-radius: 25px 0px 0px 25px;
  border: 1px solid $lightGrey;
}
.customIcon {
  color: $lightGrey;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.p-datepicker table td > span,
.p-calendar .p-inputtext {
  font-family: "avenirMedium";
}
.p-datepicker table td.p-datepicker-today > span.p-highlight,
.p-datepicker table td > span.p-highlight {
  color: $mainColor;
  background-color: rgb(0 170 49/ 20%);
}
.p-datepicker .p-datepicker-header {
  border-bottom: unset !important;
}
.icon {
  width: 25px;
  height: 25px;
}
.p-menu {
  padding: unset !important;
}
.p-menu .p-submenu-header {
  display: none;
}
.p-menu .p-menuitem {
  padding-top: 1rem;
  padding-bottom: 1rem;
  &:hover {
    background-color: rgb(0 170 49/ 20%) !important;
  }
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $mainColor !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background-color: $mainColor;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: unset !important;
  border-color: $mainColor !important;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background-color: unset !important;
  box-shadow: unset !important;
}
.p-checkbox,
.p-checkbox .p-checkbox-box {
  width: 30px !important;
  height: 30px !important;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #333333;
  border-radius: 2px !important;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 20px !important;
}
.centertd,
.centerth {
  text-align: center;
}
.custom-anchor,
.custom-anchor:hover {
  color: #00aa31;
  text-decoration: underline;
}
.right-0 {
  right: 0;
}
.p-paginator {
  background-color: unset !important;
}
.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator
  .p-paginator-last:not(.p-disabled):not(.p-highlight):hover
  .p-paginator
  .p-paginator-next,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next {
  background: $lightGreen !important;
  color: $mainColor !important;
  border-radius: 2px;
  font-family: "avenirMedium";
  font-size: 14px;
  min-width: 38px !important;
  height: 32px !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: $mainColor !important;
  color: $whiteColor !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: rgb(0 170 49/ 20%);
  box-shadow: unset !important;
}
.form-control.is-invalid,
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: transparent !important;
  border-bottom: 1px solid $redColor !important;
}
.p-multiselect-item {
  font-family: "avenirMedium";
  font-size: 16px;
}
.p-sidebar .p-sidebar-content {
  padding: 0 !important;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-family: avenirHeavy;
  font-size: 22px;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-family: avenirMedium;
  font-size: 16px;
  & a{
    color: $blackColor !important;
    text-decoration: unset !important;
  }
}
.validateBtn .p-button,
.validateBtn .p-button:enabled:active,
.validateBtn .p-button:enabled:hover {
  background: #00aa31 !important;
  border-color: #00aa31 !important;
  &:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgb(0 170 49 / 20%),
      0 1px 2px 0 black;
  }
  font-family: avenirMedium;
  font-size: 16px;
}
.white-space {
  white-space: nowrap;
}
.p-tabmenu-nav-content {
  border-radius: 20px;
  padding: 0.5rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  font-family: avenirMedium;
  font-size: 14px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-family: avenirHeavy;
  font-size: 16px;
}
.active {
  color: $mainColor;
  background: #c6e6d7;
}
.p-inputswitch.p-focus .p-inputswitch-slider,
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: unset !important;
}
.deleteBtn {
  background: $redColor;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: $whiteColor;
  border: solid #00aa31;
  border-width: 0 0 0 6px;
  color: #00aa31;
}
.p-toast-message-content {
  background-color: $whiteColor;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  color: $mainColor;
}
.p-disabled {
  opacity: unset !important;
}
p-dropdown.is-invalid .p-dropdown,
p-calendar.is-invalid .p-calendar .p-inputtext {
  border: 1px solid $redColor !important;
  .pi-chevron-down:before {
    color: $redColor !important;
  }
}
.clickable {
  cursor: pointer;
}
.underlined {
  text-decoration: underline;
}
::-webkit-scrollbar-thumb {
  background-color: $deepGrey;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: transparent;
}
.p-sidebar-header {
  display: none !important;
}
.p-dropdown,
.p-multiselect {
  border-color: transparent !important;
}
.p-slider-horizontal .p-slider-range {
  background-color: $mainColor !important;
  border-color: $mainColor !important;
}
.p-slider .p-slider-handle {
  border: 2px solid $mainColor !important;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: $mainColor !important;
}
.p-slider .p-slider-handle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.lineHeight {
  line-height: 30px;
}
.p-tooltip-text	{
  font-family: avenirMedium;
  font-size: 12px;
}
